<template>
  <tr id="myrow" style="padding: 0px;">

    <td style="text-align: center;padding: 0px;"> {{ myindex + 1 }} </td>

    <td style="padding: 0px;">
      <select ref="el_conttype" class="form-control" v-if="detail" v-model="detail.containertype" @change="rowValueEqualizer(1)" >
        <option value="1" selected>Pipe</option>
        <option value="2">Beam</option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input v-if="detail.containertype == 1" type="number" class="form-control text-center" placeholder="No of Beams" v-model="detail.beams"  @change="rowValueEqualizer(index, 2)"  />
      <input v-if="detail.containertype == 2" type="text" class="form-control" placeholder="Beam No" maxlength="10" required v-model="detail.container.name"  @change="find_container_byname(index, detail.container.name)"   />
    </td>

    <td style="padding: 0px;">
      <select class="form-control" v-model="detail.quality_id" @change="rowValueEqualizer(index, 3)" :disabled="detail.containertype == 2" >
        <option v-for="quality in qualities" v-bind:value="quality.id">
          {{ quality.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;">
      <select class="form-control" v-model="detail.color_id" @change="rowValueEqualizer(index, 4)" :disabled="detail.containertype == 2" >
        <option v-for="color in colors" v-bind:value="color.id">
          {{ color.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.000"  v-if="detail" v-model="detail.yarn_weight"  @change="rowValueEqualizer(index, 5)" required/>
    </td>

    <td style="padding: 0px;text-align: right">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-icon btn-rounded btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>

  </tr>
</template>

<script>
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'JobworkDeliveryDetailRow',
  store,
  components: {

  },
  props: {
    myrow: {
      type: Object,
      default: () => JSON.parse('{"idx":0,"containertype":"","container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"item_id":0,"item":{"id":0,"nature":0,"name":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0},"quality":{"id":0,"name":"","p_name":""},"rate":0,"wgt":0},"desc":"","quality_id":0,"quality":{"id":0,"name":""},"color_id":0,"color":{"id":0,"name":""},"beams":0,"gross_weight":0,"beam_weight":0,"yarn_weight":0}')
    },
    myindex: {
      type: Number,
      default: () => 0
    },
    colors: {
      type: Array,
      default: () => []
    },
    qualities: {
      type: Array,
      default: () => []
    },
  },
  beforeMount () {
    this.detail = this.myrow;
  },
  mounted(){
    const self = this;
    self.$refs.el_conttype.focus();
  },
  data () {
    return {
      index: 0,
      jobworker : 0,
      detail : JSON.parse('{"idx":0,"containertype":"","container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"item_id":0,"item":{"id":0,"nature":0,"name":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0},"quality":{"id":0,"name":"","p_name":""},"rate":0,"wgt":0},"desc":"","quality_id":0,"quality":{"id":0,"name":""},"color_id":0,"color":{"id":0,"name":""},"beams":0,"gross_weight":0,"beam_weight":0,"yarn_weight":0}'),
      items: [],
      mapItems: new Map(),
    }
  },
  methods:{
    removeRow(){
      this.$emit('onRemoveRow', (this.$data.detail.idx * -1) );
    },
    find_container_byname(index, con_name){
      const self = this;

      const requestOptions = {
        method:  'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#myrow').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${con_name}`,requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok){

          if( !_.isNull(resp.data) && resp.data.item_id > 1) {

            self.$data.detail.container = resp.data;
            self.$data.detail.item_id = resp.data.item.id;
            self.$data.detail.item = resp.data.item;

            self.$data.detail.color_id = resp.data.item.color.id;
            self.$data.detail.color = resp.data.item.color;

            self.$data.detail.quality_id = resp.data.item.quality.id;
            self.$data.detail.quality = resp.data.item.quality;

            self.$data.detail.desc = resp.data.item.name;

            self.$data.detail.gross_weight = resp.data.weight;
            self.$data.detail.beam_weight = resp.data.base_weight;
            self.$data.detail.yarn_weight = resp.data.yarn_weight;

          }else{
            swal (  {title:"Oops" ,  text: "Invalid Container or Container may be empty",  type: "error"});
            self.$data.detail.container = {"id":0,"name":""};
            self.$data.detail.item_id = 0;
            self.$data.detail.item = {"id":0,"name":""};

            self.$data.detail.color_id = 0;
            self.$data.detail.color = {"id":0,"name":""};

            self.$data.detail.quality_id = 1;
            self.$data.detail.quality = {"id":0,"name":""};

            self.$data.detail.desc = "";

            self.$data.detail.gross_weight = 0;
            self.$data.detail.beam_weight = 0;
            self.$data.detail.yarn_weight = 0;
          }

          self.rowValueEqualizer(index,2);

        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:3000});
        }

      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error", timer:3000});
      }).finally(function (){
        $('#myrow').unblock();
      });
    },
    rowValueEqualizer(idx, col) {
      try {

        const self = this;
        let detail = this.$data.detail;

        switch (col) {
          case 1: //Work Order

            break;
          case 2: //Item

            // detail.item = this.$data.mapItems.get(detail.item.id);
            // detail.wgt = this.$data.mapItems.get(detail.item.id).wgt.toFixed(3);
            // detail.gst_rate = this.$data.mapItems.get(detail.item.id).group.gst;
            // detail.item.group.gstrate = this.$data.mapItems.get(detail.item.id).group.gst;
            self.$emit('value_changed',detail);

            break;
          case 3: //Rate

            // var baseQty = toBaseNumber(detail.qty, detail.item.group.cf );
            //
            // detail.rate = parseFloat(detail.rate);
            //
            // this.$data.detail.taxable_amt = baseQty * detail.rate;
            // this.$data.detail.tax_amt = (detail.taxable_amt * (detail.gst_rate / 100)).toFixed(2);
            // this.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt)).toFixed(2);
            // this.$data.detail.tax_amt = parseFloat(detail.tax_amt).toFixed(2);
            // this.$data.detail.tax_amt = detail.tax_amt.toFixed(2);
            // this.$data.detail.taxable_amt = (detail.qty * detail.rate).toFixed(2);
            break;
          case 4:  //Qty

            // var baseQty = toBaseNumber(detail.qty, detail.item.group.cf );
            //
            // detail.qty = parseFloat(detail.qty);
            //
            // this.$data.detail.wgt = (baseQty * this.$data.mapItems.get(this.$data.detail.item.id).wgt).toFixed(3);
            //
            // this.$data.detail.taxable_amt = baseQty * detail.rate;
            // this.$data.detail.tax_amt = (detail.taxable_amt * (detail.gst_rate / 100)).toFixed(2);
            // this.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
            // this.$data.detail.tax_amt = parseFloat(detail.tax_amt).toFixed(2);
            // this.$data.detail.tax_amt = detail.tax_amt.toFixed(2);
            // this.$data.detail.taxable_amt = (baseQty * detail.rate).toFixed(2);
            break;
          case 5: //Weight
            self.$emit('value_changed',detail);
            break;
          case 6: //Taxable Amount

            // this.$data.detail.taxable_amt = parseFloat(detail.taxable_amt);
            // this.$data.detail.rate = (detail.taxable_amt / detail.qty).toFixed(2);
            // this.$data.detail.tax_amt = (detail.taxable_amt * (detail.gst_rate / 100)).toFixed(2);
            // this.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt)).toFixed(2);
            break;
        }
      }catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }

  /* For IE10 */
  select::-ms-expand {
    display: none;
  }
</style>
